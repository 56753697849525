import React, { useRef, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  ExpandMore,
  LightbulbCircle,
  Article,
  UploadFile,
  Google,
  ReadMore,
  Download,
  RocketLaunch,
  Share,
} from "@mui/icons-material";
import { useNav } from "contexts";
import { getQuizzes } from "api/quiz";
import prettyDate from "util/prettyDate";
import { notifyFailure } from "toastNotifications";
import { QuizShareModal } from "components/QuizShareModal";

const QuizList = ({ quizzesLoading, quizzes, setQuizzes }) => {
  const { navigate } = useNav();
  const pollingRef = useRef();
  const [showShareModalForQuiz, setShowShareModalForQuiz] = useState(null);

  useEffect(() => {
    let pollQuizzes = async () => {
      let quizzes = await getQuizzes();
      setQuizzes(quizzes);
    };
    // quizzes state has changed; check if any quizzes are pending
    const quizzesPending = quizzes.some((quiz) => quiz.status === "pending");
    // if true, wait for 3 seconds before polling again and updating state
    if (quizzesPending) {
      pollingRef.current = setTimeout(pollQuizzes, 3000);
    } else {
      // if no quizzes are pending, clear the interval
      clearInterval(pollingRef.current);
    }
  }, [quizzes]);

  if (showShareModalForQuiz !== null) {
    return <QuizShareModal onClose={() => setShowShareModalForQuiz(null)} quiz={showShareModalForQuiz} />;
  }

  // render accordions (full width) with quiz data
  if (!quizzesLoading && quizzes.length === 0) {
    return (
      <Box width={1} display={"flex"} alignItems={"top"} justifyContent={"center"} flexDirection={"column"}>
        <Typography variant="h4" gutterBottom color="primary" textAlign={"center"}>
          my quizzes
        </Typography>
        <Typography variant="h6" gutterBottom color="primary" textAlign={"center"}>
          You have no quizzes. Use the form above to generate a quiz.
        </Typography>
      </Box>
    );
  } else if (quizzesLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"200px"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={1} display={"flex"} alignItems={"top"} justifyContent={"center"} flexDirection={"column"}>
      <Typography variant="h4" gutterBottom color="primary" textAlign={"center"}>
        my quizzes
      </Typography>
      {/* show a map of icons to meaning */}
      <Box
        id="icon-map"
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={1}
        boxShadow={2}
        padding={2}
        borderRadius={2}
        bgcolor={"background.paper"}
        marginBottom={2}
      >
        <Typography textAlign={"center"} variant="body1" color="text.secondary">
          The following icons represent the source of the quiz questions.
        </Typography>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
          <LightbulbCircle color="secondary" />
          <Typography>Topic</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
          <Article color="secondary" />
          <Typography>Wiki Article</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
          <UploadFile color="secondary" />
          <Typography>Uploaded PDF</Typography>
        </Box>
      </Box>
      {quizzes.map((quiz) =>
        quiz.question_generation_logs.map((qgl) => (
          <Accordion key={qgl.id}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={1}
                justifyContent={"start"}
                width={1}
              >
                {qgl.generated_from === "topic" && <LightbulbCircle color="secondary" />}
                {qgl.generated_from === "wiki_article" && <Article color="secondary" />}
                {qgl.generated_from === "upload_pdf" && <UploadFile color="secondary" />}
                <Typography>
                  {qgl.generated_from === "topic" && <strong>{qgl.topic}</strong>}
                  {qgl.generated_from === "wiki_article" && <strong>{qgl.wiki_article.title}</strong>}
                  {qgl.generated_from === "upload_pdf" && <strong>{qgl.upload_pdf.split("/")[1]}</strong>}
                  <br />
                  {quiz.questions.length} Questions
                </Typography>

                <Box
                  variant={qgl.status === "pending" ? "outlined" : "contained"}
                  sx={{
                    textTransform: "uppercase",
                  }}
                  size="small"
                  marginLeft={"auto"}
                >
                  {qgl.status === "pending" ? (
                    <Box alignItems={"center"} justifyContent={"end"} display={"flex"} gap={1}>
                      <Typography variant="caption">Generating</Typography> <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Box alignItems={"center"} justifyContent={"end"} display={"flex"} gap={1}>
                      <Typography textAlign={"right"} variant="caption">
                        Generated {prettyDate({ dateString: qgl.created_at })}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"end"}
                marginBottom={1}
                gap={2}
              >
                {qgl.generated_from === "upload_pdf" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<Download />}
                    onClick={() => {
                      // download PDF here TODO
                      notifyFailure("Feature not implemented yet");
                    }}
                  >
                    Download PDF
                  </Button>
                )}
                {qgl.generated_from === "topic" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    LinkComponent={"a"}
                    startIcon={<Google />}
                    target="_blank"
                    href={`https://www.google.com/search?q=${encodeURIComponent(qgl.topic)}`}
                  >
                    Google this topic
                  </Button>
                )}
                {qgl.generated_from === "wiki_article" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    LinkComponent={"a"}
                    target="_blank"
                    startIcon={<ReadMore />}
                    href={qgl.wiki_article.url}
                  >
                    Visit Wikipedia Article
                  </Button>
                )}
                <Button
                  size="small"
                  onClick={() => {
                    setShowShareModalForQuiz(quiz);
                  }}
                  variant="contained"
                  color="secondary"
                  startIcon={<Share />}
                >
                  Share
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    navigate(`/quiz/${quiz.id}`);
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<RocketLaunch />}
                >
                  Practice
                </Button>

                {/* eventually show edit button here */}
              </Box>
              {/* render quiz questions */}
              <Box display={"flex"} flexDirection={"column"} width={1} marginTop={2}>
                {quiz.questions.map((question, index) => (
                  <Box key={index} display={"flex"} flexDirection={"column"} width={1} marginBottom={1}>
                    {question.t === "mlt" && (
                      <Typography
                        variant="body1"
                        boxShadow={2}
                        padding={1}
                        borderRadius={2}
                        bgcolor={"background.paper"}
                      >
                        <strong>{index + 1}. </strong>
                        {question.q}
                      </Typography>
                    )}
                    {question.t === "fib" && (
                      <Typography
                        variant="body1"
                        boxShadow={2}
                        padding={1}
                        borderRadius={2}
                        bgcolor={"background.paper"}
                      >
                        <strong>{index + 1}. </strong>
                        {question.q.replace(/<<\d+>>/g, "____")}
                      </Typography>
                    )}
                    {question.t === "tf" && (
                      <Typography
                        variant="body1"
                        boxShadow={2}
                        padding={1}
                        borderRadius={2}
                        bgcolor={"background.paper"}
                      >
                        <strong>{index + 1}. </strong>True or False: {question.q}
                      </Typography>
                    )}
                    {question.t === "oe" && (
                      <Typography
                        variant="body1"
                        boxShadow={2}
                        padding={1}
                        borderRadius={2}
                        bgcolor={"background.paper"}
                      >
                        <strong>{index + 1}. </strong>Open-ended: {question.q}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )),
      )}
    </Box>
  );
};

export default QuizList;
